import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { environment } from '@env/environment';


@Injectable({
  providedIn: 'root'
})
export class EndpointsService {

  private itemSold = false;
  public itemSoldObs: BehaviorSubject<boolean>;
  private storeUrls = {
    google: 'https://app.appsflyer.com/com.btfit?pid=ecommerce&c=prevenda',
    googleSold: 'https://app.appsflyer.com/com.btfit?pid=ecommerce&c=posvenda',
    apple: 'https://app.appsflyer.com/id1034954940?pid=ecommerce&c=prevenda',
    appleSold: 'https://app.appsflyer.com/id1034954940?pid=ecommerce&c=posvenda',
    manageApple: 'https://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/DirectAction/manageSubscriptions'
  }
  private endpoints = {
    // Endpoints from BTFIT app C# backend
    appLive: {
      // Auth
      'auth.appAuth': 'BTLive.Security.Host/ChannelAuthenticationServiceRest.svc/auth',
      // TIM
      'tim.associate': 'BTFit.ECommerce.Host/AcotelContractREST.svc/customer/associate/partner/tim',
    },
    // Endpoints from BTFIT 'payment' Grails backend
    payment: {
      // Products
      'products.byProductId': 'payment/apppurchase/plans?product_id={{0}}',
      // Coupon
      'coupon.insert': 'payment/coupon/validate',
      'coupon.remove': 'payment/coupon/invalidate?id={{0}}',
      'coupon.getProductByCoupon': 'payment/coupon/getProductByCoupon/{{0}}',
      'trainingProgram.all': 'services/trainingProgram',

      // Bodytech user login
      'user.bodytechLogin': 'payment/bodytech/login',

      // Formula user login
      'user.formulaLogin': 'payment/formula/login',

      // register with phone
      'user.phoneAssociate': 'payment/user/phone/associate',
      'user.pincodeValidate': 'payment/pincode/validate',
      'user.pincodeSend': 'payment/pincode/send',
      'user.phoneGet': 'payment/user/phone/get',

      // corporative
      'corporative.sendEmail': 'payment/corporate/form',
      'corporative.getPlans': 'payment/corporate/plans',

    },
    // Endpoints from BTFIT 'profile' Grails backend
    profile: {
      // User
      'user.check': 'services/freeclass/check',
      'user.emailLogin': 'services/login',
      'user.emailRegister': 'services/user',
      'user.socialLoginRegister': 'services/login/external',
      'user.forgotPasswordEmail': 'services/login/forgotPassword',
      'user.changeForgottenPassword': 'services/login/changePassword',

      'user.associations': 'services/user/associations',
      'user.associateWithFacebook': 'services/user/facebook/associate',
      // Activity
      'activity.all': 'services/activity/list',
      // Banner
      'banner.all': 'services/banner',

      // Live Group Classes
      'liveclass.zoomSignature': 'services/liveclass/credentials',
      'favoriteLiveClasses.list': 'services/favoriteliveclass/list',
      'favoriteLiveClasses.save': 'services/favoriteliveclass/save',
      'liveclass.list': 'services/liveclass',
      'liveClasses.listWithoutAuth': 'services/liveclass/partial',
      'liveClasses.listActivities': 'services/liveclass/activities',

      // Challenges and PDT
      'challengeschallengesOrPdt.simpleList': 'services/challenges/simplelist',
      'challengesOrPdt.list': 'services/{{0}}',
      'challenges.ranking': 'services/ranking/challenges', // to do - ver esse depois
      'challengesOrPdt.detail': 'services/{{0}}/{{1}}',
      'challengesOrPdt.detail.playlist': 'services/{{0}}/{{1}}/playlist',
      'challengesOrPdt.detail.subscribe': 'services/{{0}}/{{1}}/subscribe',
      'challengesOrPdt.detail.checkin': 'services/{{0}}/{{1}}/playlist/{{2}}',

      'commentChallenges.list': 'services/{{0}}/{{1}}/comments?last_millis={{2}}',
      'commentChallenges.new': 'services/{{0}}/{{1}}/comments/new',
      'commentChallenges.reply': 'services/{{0}}/{{1}}/comments/reply',
      'commentChallenges.like': 'services/{{0}}/{{1}}/comments/like',
      'commentChallenges.report': 'services/{{0}}/{{1}}/comments/report',

      'commentPCC.list': 'services/{{0}}/{{1}}/comment?last_millis={{2}}',
      'commentPCC.new': 'services/{{0}}/{{1}}/comment',
      'commentPCC.reply': 'services/{{0}}/{{1}}/comment/reply',
      'commentPCC.like': 'services/{{0}}/{{1}}/comment/like',
      'commentPCC.report': 'services/{{0}}/{{1}}/comment/report',

      'configuration.sessionLog': 'services/session/register',

      'customer.subscriptionInformation': 'services/customer/subscriptionInformation'
    },
    // Endpoints from BTFIT 'ecommerce' Grails backend
    ecommerce: {
      // Products
      'products.retrieve': 'ecommerce/product/retrieveProducts',
      'products.retrieveById': 'ecommerce/product/',
      'products.retrieveByIdWithoutLogin': 'ecommerce/product/get/',
      'products.customerProducts': '/ecommerce/customer/userProduct',
      'purchase.purchase': 'ecommerce/purchase',
      'purchase.promoPurchase': 'ecommerce/purchase/specialOffer',
      'purchase.recurrentPurchase': 'purchase/recurrentPurchase',
      'purchase.simplePurchase': 'purchase/simplePurchase',
      'purchase.cancelRecurrentPurchase': 'ecommerce/purchase/cancelRecurrentPurchase',
      'products.getByUser': 'ecommerce/customer/products',
      'programs.retrieve': 'ecommerce/product/retrieveTrainingPrograms',

      'configuration.getConfigurationByMainKey': 'ecommerce/configuration/retrieveConfigurationByMainKey/{{0}}',


      'purchase.validateCardBrand': 'ecommerce/validateCardBrand',
    },
    // Endpoints from BTFIT 'backoffice' an partition inside the Payment Backend
    backoffice: {
      'legalPublication.list': 'payment/backoffice/legalPublication/list',
    },
    // External APIs
    external: {
      'currency.ratio': 'https://free.currencyconverterapi.com/api/v5/convert?q={{0}}&compact=ultra',
      'client.ipAddress': 'https://api.ipify.org?format=json',
      'client.location': 'https://api.ipstack.com/{{0}}?access_key={{1}}',
      'location.states': 'https://servicodados.ibge.gov.br/api/v1/localidades/estados',
      'location.cities': 'https://servicodados.ibge.gov.br/api/v1/localidades/estados/{{0}}/municipios',
    },
    microservice: {
      // User
      'user.changeEmail': 'customer/change-email',
      // Activity
      'activity.feedback': 'exercise/free/class/feedback',
    }
  }

  constructor() {
    this.itemSoldObs = new BehaviorSubject(this.itemSold);
  }

  public setItemSold(sold: boolean) {
    if (this.itemSold !== sold) {
      this.itemSold = sold;
      this.itemSoldObs.next(this.itemSold);
    }
  }

  public getStoreUrls() {
    return this.storeUrls;
  }

  public get(urlKey: string, params?: Array<any> | Object): string {
    let origin: string;
    let external: boolean;
    for (const key in this.endpoints) {
      if (this.endpoints[key].hasOwnProperty(urlKey)) {
        origin = key;
        external = key === 'external';
      }
    }
    if (!origin) { return '' }

    const baseUrl = environment.baseUrl[origin];
    const endpoint = this.endpoints[origin][urlKey];

    if (!endpoint || !baseUrl && !external) { return '' }

    let path = (baseUrl || '') + endpoint;
    if (params) {
      for (const i in params) {
        if (params.hasOwnProperty(i)) {
          path = path.split(`{{${i}}}`).join(params[i].toString());
        }
      }
    }

    return path;
  }
}
