<div id="menu" class="navbar-collapse collapse" #navbar>
  <ul class="nav navbar-nav navbar-right">
    <li>
      <a id="nav-group-classes-2"
        [routerLink]="['/dashboard/pdt']"
        class="scroll-link links" i18n="@@menuPrograms"
        [navbar]="navbar"
        navbarClose>
        PROGRAMAS DE TREINAMENTO
      </a>
    </li>
    <li>
      <a id="nav-group-classes-2"
        [routerLink]="['/dashboard/groupclasses']"
        class="scroll-link links" i18n="@@menuGroupClasses"
        [navbar]="navbar"
        navbarClose>
        AULAS COLETIVAS
      </a>
    </li>
    <li *ngIf="setToPt">
      <a id="nav-group-classes-2"
        [routerLink]="['/live-classes/schedule']"
        class="scroll-link links" i18n="@@menuGroupLiveClasses"
        [navbar]="navbar"
        navbarClose>
        COLETIVAS AO VIVO
      </a>
    </li>
    <li>
      <a id="blog"
        class="scroll-link links"
        href="https://blog.bt.fit/"
        target="_blank"
        i18n="@@menuBlog"
        [navbar]="navbar"
        navbarClose
        >
        BLOG
      </a>
    </li>
    <li>
      <a id="nav-plans"
        routerLink="/e-commerce/plans"
        class="scroll-link links"
        [navbar]="navbar"
        navbarClose>
        BTFIT PREMIUM
      </a>
    </li>
    <li id="divider"></li>
    <li>
      <a id="user" [routerLink]="['/dashboard/profile']" class="scroll-link"
      [navbar]="navbar"
      navbarClose>
        <img
          loading="lazy"
          [src]="userPhoto"
          alt="Foto de perfil"
        >
        <span>{{ user.user?.name.split(" ")[0] | uppercase }}</span>
      </a>
    </li>
    <li class="hide-collapsed">
      <a id="nav-logout"
        href="#"
        class="scroll-link language-button"
        (click)="logout()"
        i18n="@@menuLeave">SAIR</a>
    </li>
  </ul>
</div>
