import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LanguageService } from '@services';
import { ModalService } from '@services/modal.service';


@Component({
  selector: 'bf-language-modal',
  templateUrl: './language-modal.component.html',
})
export class LanguageModalComponent implements OnInit {
  public language: string;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private languageService: LanguageService,
    private modalService: ModalService,
    private router: Router
  ) { }

  ngOnInit() : void {
    this.language = this.languageService.getLanguage()
  }

  setChosenLanguage(lang) {
    if (this.language === lang) { return }

    this.language = lang;
    this.languageService.setLanguage(this.language);
    this.document.location.href = this.changeLanguageUrl(lang);
  }

  closeModal(): void {
    this.modalService.closeModal();
  }

  private changeLanguageUrl(lang: string) {
    return window.location.origin + '/' + lang.split('-')[0] + '/#' + this.router.url;
  }
}
