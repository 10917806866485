<div class="store-buttons-group">
  <a id="store-google" target="_blank" rel="noreferrer" class="store-button" [href]="google">
    <img
      loading="lazy"
      i18n-src="@@commonGooglePlayButton"
      height="52"
      width="174"
      src="assets/img/google-play-pt.webp"
      alt="google play"
    >
  </a>
  <a id="store-apple" target="_blank" rel="noreferrer" class="store-button" [href]="apple">
    <img
      loading="lazy"
      i18n-src="@@commonAppleStoreButton"
      height="52"
      width="174"
      src="assets/img/apple-store-pt.webp"
      alt="app store"
    >
  </a>
</div>
