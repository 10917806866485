
export interface Plan {
    code: string;
    inTrialPeriod?: boolean;
    daysOfTrial?: number;
    discountedRecurrences: number;
    id: number;
    merchantOrderId: number;
    introdutoryPercentageDiscount: number;
    nextRecurrency: string;
    planType: PlanType;
    currency: string;
    price: number;
    monthlyPrice?: number;
    originalPrice: number;
    status?: number; // 12 -> Pagamento sendo processado | Caso contrário -> aprovado
    installments?: number;
    anualPercentageDiscount?: number;
    firstCharge?: Date;
    active?: boolean;
    cancellationDate?: string;
}

// Retorno do serviço C#
export enum PlanType {
    Monthly = 1,
    Annual = 2,
    Semiannual = 4,
    Biennial = 6,
}

// APPROVED(1), // Meio de pagamento apto a ser capturado ou pago(Boleto)
// PAYMENTCONFIRMED(2), // Pagamento confirmado e finalizado
// DENIED(3),
// VOIDED(10), //Pagamento cancelado
// REFUNDED(11), // Pagamento Cancelado/Estornado
// PENDING(12), // Esperando retorno da instituição financeira
// ABORTED(13), // Pagamento cancelado por falha no processamento
// SCHEDULED(20) // Recorrência agendada
